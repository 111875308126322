.transaction-history__container {
  /* min-width: 200px; */
  width: 300px;

  backdrop-filter: blur(2px);
}

.transaction-history__list {
  overflow-y: scroll;
  height: 70vh;
}

.transaction-history__list::-webkit-scrollbar {
  width: 0px !important;
}

.transaction-history__list::-webkit-scrollbar-thumb {
  background-color: white;
}
