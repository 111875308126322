.scroll-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-indicator__icon {
  width: 100px;
  height: 100px;
  /* margin-top: -15px; */
  fill: white;

  animation: fade-down 2s infinite;
}
