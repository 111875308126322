/* .ontology-explainer__container { */
/* height: 5000px; */
/* } */

.ontology-explainer__video-container {
  background: black;
  position: relative;
  height: 600vh;
}

.ontology-explainer__video {
  position: sticky;
  top: 0px;
}

.ontology-explainer__text-container {
  position: relative;
  display: flex;
  height: 200vh;
}

.ontology-explainer__text {
  font-size: 3vmin;
  max-width: min(50ch, 80vmin);
  display: flex;
  flex-direction: row;
  justify-self: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1.2rem;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* position: sticky;
  top: 50%; */
}
