#timeline {
  font-size: 0.8em;
  z-index: 2;
  /* border-top: 1px solid white; */
  /* position: absolute; */
  bottom: 10px;
  /* background-color: green; */
  color: white;
}

#timeline .double-axis-bar-chart {
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.1);
  user-select: none;
  cursor: pointer;
}

#timeline .double-axis-bar-chart {
  @media only screen and (max-width: 720px) {
    font-size: unset;
  }
}

#timeline .time__tracker {
  margin-top: 0px;
  pointer-events: none;
}
