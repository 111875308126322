:root {
  --progress-tracker-width: 0vw;
}

.progress-bar__container {
  z-index: 10;
  position: fixed;
  top: 0px;
  overflow-x: hidden;
}

.progress-bar__tracker {
  height: 2px;
  background: white;
  width: var(--progress-tracker-width);
  animation: width 0.2s ease-in;
}
