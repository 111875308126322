:root {
  --progress-tracker-width: 0vw;
  --vh: 1vh;
}

body,
html {
  background: #000;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  /* overflow: hidden; */
  color: white;
  font-family: "Barlow", "Helvetica Neue", sans-serif;
}

@media only screen and (max-width: 720px) {
  * {
    font-size: 10px;
  }
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background-color: white;
}

@keyframes fade-down {
  0%,
  50% {
    opacity: 1;
    transform: translateY(0);
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(10%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 0.6s ease-in;
}

/* @font-face {
  font-family: "Barlow";
  src: url("fonts/barlow-regular.woff2") format("woff2"),
    url("fonts/barlow-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

.font-heavy {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.border-thicker {
  border-width: 2px !important;
}

.button {
  user-select: none;
}

.button:hover {
  background: white;
  color: black;
  cursor: pointer;
}

#network {
  /* z-index: 1; */
  top: 0;
  left: 0;
}

#panel {
  z-index: 3;
}

#network,
#panel {
  position: fixed;
}

.pointer {
  cursor: pointer;
}

.half-opacity {
  opacity: 0.5;
}

.full-height {
  height: calc(var(--vh, 1vh) * 100);
}

.half-height {
  height: calc(var(--vh, 1vh) * 50);
}

.vh-70 {
  height: 70vh;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 15px;
}

button {
  background: black;
  color: white;
  border-radius: 10px;
  border: 1px solid white;
  padding: 4px;
}

button:hover {
  background: white;
  color: black;
}
